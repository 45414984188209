import React from 'react';
import logo from './logo.svg';
import './App.css';
import SiteRoute from './components/modules/site-route';
import { getUserType } from './components/utils/util';

function App() {
  getUserType();
  return (
    <SiteRoute />
  );
}

export default App;
