import React from 'react';
import {isLoggedIn} from '../services/auth-service'
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  // Add your own authentication check here.
    const Logined = isLoggedIn();
    const location=useLocation();
  return (
    Logined
        ?<Outlet/>
        :<Navigate to="/login" state={{ from:location }} replace />
  );
};

export default PrivateRoute;
