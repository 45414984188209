import { getUserTypes } from "../api/apicalling-function/apicalling-function";
// import React, { useEffect, useState } from 'react';
// const someCommonValues = ['common', 'values'];

export const setUserData = (data) => {
    localStorage.setItem('UserDetails', JSON.stringify(data))
    // sessionStorage.setItem('UserDetails', JSON.stringify(data));
};
export const getUserData = () => {
    const data = JSON.parse(localStorage.getItem('UserDetails'))
    // const data=JSON.parse(sessionStorage.getItem('UserDetails'));
    return data;
};

export const getUserType = () => {
    if(sessionStorage.getItem('UserTypes')){

    }else{
        getUserTypes()
        .then(data => {
            sessionStorage.setItem('UserTypes', JSON.stringify(data));
        })
    }
}
export const getUserTypeSession = () => {
    const data=JSON.parse(sessionStorage.getItem('UserTypes'));
    return data;
}
export const covertJsonToArray = (data) =>{
    const array=JSON.parse(data);
    return array;
}
export const covertToJsonString = (data) =>{
    const string=JSON.stringify(data);
    return string;
}