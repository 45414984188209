import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoute from "./private-route";

import Loader from '../loader/loader';

// Lazy-loaded components
const HomePage = lazy(() => import("../modules/site-module/home-page/home-page"))
const Category = lazy(() => import("./category/category"));
const ItemDetails = lazy(() => import("./Item-details/item-details"));
// const AboutUs = lazy(() => import("./site/about-us/about-us"));
const Dashboard = lazy(() => import("./customer/dashboard/dashboard-page"));
const Profile = lazy(() => import("./customer/profile/profile-page"));
const Login = lazy(() => import("./customer/login-page/login-page"));
// const ReturnPolicy = lazy(() => import("./site/returnpolicy/returnpolicy-page"));
const CustomerCart = lazy(() => import("./customer/cart/cart-page"));
const WishList = lazy(() => import("./customer/wishlist/wishlist"));
const CheckOut = lazy(() => import("./customer/checkout/checkout-page"))
const Register = lazy(() => import("./customer/register-page/register"));
// const SiteMap = lazy(() => import("./site/site-map/site-map"));
// const ContactUS = lazy(() => import("./site/contact-us/contact-us"));
const ChangePassword = lazy(() => import("./customer/login-passwordreset/new-passwordchange/new-password"));
const ProductListing = lazy(() => import("./product-listing/product-listing"));
const PasswordOtp = lazy(() => import("./customer/login-passwordreset/otp-verification/otp-verification"));
const OtpVerify = lazy(() => import("./customer/login-passwordreset/otp-verification/otp-verification"));
// const Blog = lazy(() => import("./site/blog/blog"));
// const BlogDetails = lazy(() => import("./site/blog/blog-details/blog-details"));
// const Careers = lazy(() => import("./site/careers/careers"));
// const PrivacyPolicy = lazy(() => import("./site/privacy-policy/privacy-policy"));
// const TermsService = lazy(() => import("./site/terms-service/terms-service"));
const TrackMyOrder = lazy(() => import("./customer/trackmyorder/track-myorder"));
// const Help = lazy(() => import("./site/help/help"));
// const Shopping = lazy(() => import("./site/shopping/shopping"));
// const SeasonalProducts = lazy(() => import("./site/seasonal-products/seasonal-products"));
// const AfterProducts = lazy(() => import("./site/after-products/after-products"));
// const TrendingProducts = lazy(() => import("./trending-products/trending-products"));
// const SpogSignature = lazy(() => import("./spog-signature/spog-signature"));
// const DesignConsultancy = lazy(() => import("./design-consultancy/design-consultancy"));
// const GetYourOffers = lazy(() => import("./get-youroffers/get-youroffers"));
// const OtpVerification = lazy(() => import("./customer/login-passwordreset/otp-verification/otp-verification"));
const NewPassword = lazy(() => import("./customer/login-passwordreset/new-passwordchange/new-password"));
// const RegisterOtp = lazy(() => import("./customer/register-otp/register-otp/register-otp"));
const Address = lazy(() => import("./customer/address/address-listing/address-listing"));


const SiteRout = () => {
  window.scrollTo(0, 0);

  return (
    <BrowserRouter>
      <Routes>

        <Route element={<PrivateRoute />}>
          <Route
            path="/customer-cart"
            element={
              <Suspense fallback={<Loader />}>
                <CustomerCart />
              </Suspense>
            }
          />
          <Route
            path="/wishlist"
            element={
              <Suspense fallback={<Loader />}>
                <WishList />
              </Suspense>
            }
          />
          <Route
            path="/profile"
            element={
              <Suspense fallback={<Loader />}>
                <Profile />
              </Suspense>
            }
          />
          <Route
            path="/dashboard"
            element={
              <Suspense fallback={<Loader />}>
                <Dashboard />
              </Suspense>
            }
          />
          <Route
            path="/checkout"
            element={
              <Suspense fallback={<Loader />}>
                <CheckOut />
              </Suspense>
            }
          />
          
          <Route
            path="/track-myorder"
            element={
              <Suspense fallback={<Loader />}>
                <TrackMyOrder />
              </Suspense>
            }
          />

          <Route
            path="/ChangePassword"
            element={
              <Suspense fallback={<Loader />}>
                <ChangePassword />
              </Suspense>
            }
          />
          
        </Route>

        <Route
          path="/"
          element={
            <Suspense fallback={<Loader />}>
              <HomePage />
            </Suspense>
          }
        />

        <Route
          path="/login"
          element={
            <Suspense fallback={<Loader />}>
              <Login />
            </Suspense>
          }
        />

        <Route
          path="/register"
          element={
            <Suspense fallback={<Loader />}>
              <Register />
            </Suspense>
          }
        />
        <Route
          path="/category/:CategoryId"
          element={
            <Suspense fallback={<Loader />}>
              <Category />
            </Suspense>
          }
        />
        <Route
          path="/details-page/:ProductId"
          element={
            <Suspense fallback={<Loader />}>
              <ItemDetails />
            </Suspense>
          }
        />
        <Route
          path="/details-page/:ProductId/:CartId"
          element={
            <Suspense fallback={<Loader />}>
              <ItemDetails />
            </Suspense>
          }
        />

        {/* 
        <Route
          path="/about-us"
          element={
            <Suspense fallback={<Loader />}>
              <AboutUs />
            </Suspense>
          }
        />

        <Route
          path="/returnpolicy"
          element={
            <Suspense fallback={<Loader />}>
              <ReturnPolicy />
            </Suspense>
          }
        />

        <Route
          path="/site-map"
          element={
            <Suspense fallback={<Loader />}>
              <SiteMap />
            </Suspense>
          }
        />
        <Route
          path="/contact-us"
          element={
            <Suspense fallback={<Loader />}>
              <ContactUS />
            </Suspense>
          }
        />
         */}
        <Route
          path="/product-listing"
          element={
            <Suspense fallback={<Loader />}>
              <ProductListing />
            </Suspense>
          }
        />
        <Route
          path="/product-listing/:SearchKey"
          element={
            <Suspense fallback={<Loader />}>
              <ProductListing />
            </Suspense>
          }
        />
        <Route
          path="/forget-password"
          element={
            <Suspense fallback={<Loader />}>
              <PasswordOtp />
            </Suspense>
          }
        />
        <Route
          path="/verify-otp"
          element={
            <Suspense fallback={<Loader />}>
              <OtpVerify />
            </Suspense>
          }
        />
        <Route
          path="/new-password"
          element={
            <Suspense fallback={<Loader />}>
              <NewPassword />
            </Suspense>
          }
        />
         <Route
            path="/address"
            element={
              <Suspense fallback={<Loader />}>
                <Address />
              </Suspense>
            }
          />
        {/*
        
        <Route
          path="/blog"
          element={
            <Suspense fallback={<Loader />}>
              <Blog />
            </Suspense>
          }
        />
        <Route
          path="/careers"
          element={
            <Suspense fallback={<Loader />}>
              <Careers />
            </Suspense>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <Suspense fallback={<Loader />}>
              <PrivacyPolicy />
            </Suspense>
          }
        />
        <Route
          path="/terms-service"
          element={
            <Suspense fallback={<Loader />}>
              <TermsService />
            </Suspense>
          }
        />
        <Route
          path="/help"
          element={
            <Suspense fallback={<Loader />}>
              <Help />
            </Suspense>
          }
        />
        <Route
          path="/shopping"
          element={
            <Suspense fallback={<Loader />}>
              <Shopping />
            </Suspense>
          }
        />
        <Route
          path="/seasonal-products"
          element={
            <Suspense fallback={<Loader />}>
              <SeasonalProducts />
            </Suspense>
          }
        />
        <Route
          path="/after-products"
          element={
            <Suspense fallback={<Loader />}>
              <AfterProducts />
            </Suspense>
          }
        />

        <Route
          path="/blog-details"
          element={
            <Suspense fallback={<Loader />}>
              <BlogDetails />
            </Suspense>
          }
        />


        <Route
          path="/design-consultancy"
          element={
            <Suspense fallback={<Loader />}>
              <DesignConsultancy />
            </Suspense>
          }
        />
        <Route
          path="/otp-verification"
          element={
            <Suspense fallback={<Loader />}>
              <OtpVerification />
            </Suspense>
          }
        />
        <Route
          path="/spog-signature"
          element={
            <Suspense fallback={<Loader />}>
              <SpogSignature />
            </Suspense>
          }
        />
        <Route
          path="/trending-products"
          element={
            <Suspense fallback={<Loader />}>
              <TrendingProducts />
            </Suspense>
          }
        />
        <Route
          path="/offers"
          element={
            <Suspense fallback={<Loader />}>
              <GetYourOffers />
            </Suspense>
          }
        />
        
        <Route
            path="/register-otp"
            element={
              <Suspense fallback={<Loader />}>
                <RegisterOtp />
              </Suspense>
            }
          />
                  */}
                  
      </Routes>
    </BrowserRouter>
  );
};

export default SiteRout;




